<script>
export default {
  components: {},
  beforeDestroy() {
    var data = JSON.parse(
      JSON.stringify(this.$store.state.privateEvent.assistent)
    );
    this.$store.commit("privateEvent/prefetch", data);
  },
  methods: {
    setSave() {
      var data = JSON.parse(
        JSON.stringify(this.$store.state.privateEvent.unsaved)
      );
      this.$store.commit("privateEvent/update", data);

      this.nextStep();
    },
    finalStep() {},
    nextStep() {
      return false;
    },
    checkData() {
      return true;
    }
  }
};
</script>
<style lang="sass" scoped>
@import '@/assets/sass/variables'
.saveBtn
  margin-top: 20px
</style>
