<template>
  <div>
    <location />
    <anonym />
    <comments />
    <div class="flix-mt-50">
      <h4>{{ $t("time.lastMinute.title") }}</h4>
      <lastMinute />
    </div>
    <saveBtn
      v-if="checkData()"
      class="saveBtn"
      :callback="
        function() {
          setSave();
        }
      "
    />
  </div>
</template>
<script>
import def from "../default/";
export default {
  extends: def,
  components: {
    location() {
      return import("./location");
    },
    lastMinute() {
      return import("./lastMinute");
    },
    anonym() {
      return import("./anonym");
    },
    comments() {
      return import("./comments");
    }
  },
  props: {},
  data() {
    return {};
  },
  computed: {},
  mounted() {},
  methods: {
    nextStep() {
      this.$router.push({ name: "privatePreview" });
    }
  }
};
</script>
